html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* React Quill CSS Started */

.ql-tooltip {
  left :0 !important
}
.expandable-quill-editor {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.2s;

}
.ql-toolbar.ql-snow {
  background-color: #ffffffde !important;
}
.ql-container {
  border: none;

}
.ql-editor {
  min-height: 200px;
  padding: 10px;
  outline: none;
}

/* React Quill CSS Ended */